<template>
  <div
    class="inprocess-tests px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="In-Process Tests" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full mb-6">
        <Paragraph
          ><p>
            In-Process (IP) testing is used to determine the classification or
            characterization of the material in the in-process condition.
            &nbsp;Equating the transportation classification (article or
            substance is packaged) to an un-packaged or un-finished article or
            substance can expose manufacturers and users to un-foreseen risks.
          </p>
          <p>
            In-Process testing simulates in-process conditions and is used to
            augment risk-assessment of processing and handling of propellants,
            explosives, and pyrotechnics (PEP) substances and articles. Below
            &nbsp;is an&nbsp;outline for each sensitivity and reactivity
            characterization test prescribed in the technical paper
            entitled&nbsp;<a
              href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/2016-In-Process-Hazard-Classification.pdf?alt=media&token=2283c042-1abd-4e29-9d13-740bbfc68769"
              target="_blank"
              ><b>“In-Process Hazard Classification of Explosives”</b> (click to
              view)</a
            >.
          </p>
          <p>
            Additional resources including test purposes, test setup pictures,
            test outcome videos, and key parameters can be found in the SMS
            sponsored&nbsp;Test Methods Matrix™ database for both In-process and
            UN test methodologies:
            <strong
              ><em
                ><a
                  href="http://www.etusersgroup.org/test-methods-matrix"
                  target="_blank"
                  >Test Methods Matrix
                </a></em
              ></strong
            >.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pr-2">
        <img
          src="@/assets/Testing/ExplosivesLifeCycle-min.png"
          width="1070"
          height="599"
          alt=""
          class="img-responsive wp-image-12449"
        />
        <Paragraph class="my-4"
          ><p>Explosive Life-Cycle: In-Process Stages Highlighted in Blue</p>
        </Paragraph>
      </div>
      <div class="w-full md:w-2/3 mb-6 md:pl-2">
        <TitleComponent title="In-Process Test Series 1" />
        <Paragraph
          ><p>
            The purpose of these tests is to determine the fundamental material
            characteristics that are required for determining processing and
            handling hazards. These tests are conducted first since impact,
            friction, ESD, and thermal sensitivity data must be conducted on
            materials suspected of having explosive properties prior to handling
            them in the larger quantities required for the remaining test
            series. Impact, friction, ESD, and thermal sensitivity data, when
            compared to the in-process potential, can determine the margins of
            safety and therefore the level of risk achieved when manufacturing
            or using the material in processing.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Impact Test
          </h2>
          <p>
            Impact tests are used to determine the response of an explosive when
            a moving mass impacts it. This test simulates impact conditions in
            processing operations, wherein an explosive is subjected to a
            collision between moving components of the processing equipment, by
            normal handling operations, or by the inadvertent dropping of tools
            or equipment.
          </p>
          <p>
            As opposed to some of the UN Manual tests, the in-process
            sensitivity tests are designed to determine the sensitivity of the
            sample in engineering units. &nbsp;The modified Bureau of Mines
            Impact (MBOM) test can obtain that data and has been done by ATK and
            other industry leaders to make comparisons of the impact energy to
            in-process material response scenarios.
          </p>
          <p>
            Further details can be found in the SMS Sponsored Test Methods
            Matrix™ including videos, test setup pictures, test purpose, key
            test parameters and indicators: &nbsp;<a
              href="http://www.etusersgroup.org/mbom-impact-test/"
              target="_blank"
              >In-Process (IP) Series 1 – Impact Test</a
            >
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Friction Tests
          </h2>
          <p>
            These tests simulate friction conditions that may occur in a process
            when an explosive is subjected to a frictional force between moving
            components or during material handling. The tests are used to
            produce friction profiles for the sample material. The two primary
            tests performed are the ABL and BAM friction tests.
          </p>
          <p>
            In the ABL test the sample is placed on an anvil. Force is applied
            to the anvil through a hydraulic ram attached to a stationary wheel.
            A pendulum strikes the anvil and slides it under the wheel.
            &nbsp;Data is obtained in engineering units.
          </p>
          <p>
            Further details can be found in the SMS Sponsored Test Methods
            Matrix™ including videos, test setup pictures, test purpose, key
            test parameters and indicators:&nbsp;<a
              href="http://www.etusersgroup.org/abl-friction-test/"
              target="_blank"
              >In-Process (IP) Series 1 – Friction Test</a
            >
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Electrostatic Discharge Test
          </h2>
          <p>
            ESD testing is used to determine the response of an explosive when
            subjected to various levels of electrostatic discharge energy. The
            approaching needle method is most commonly used because it best
            models the common safety issues involved with ESD sensitivity.
            Electrostatic energy, stored in a charged capacitor, is discharged
            to the test sample by lowering the discharge needle until a spark is
            drawn through the sample. An infrared analyzer or sample consumption
            are means normally used to determine sample initiation.
          </p>
          <p>
            Further details can be found in the SMS Sponsored Test Methods
            Matrix™ including videos, test setup pictures, test purpose, key
            test parameters and indicators:&nbsp;<a
              href="http://www.etusersgroup.org/abl-esd-test/"
              target="_blank"
              >In-Process (IP) Series 1 – ESDTest</a
            >
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Thermal Test
          </h2>
          <p>
            The purpose of completing the in-process thermal test is determine
            the effects of time and temperature on energetic materials in the
            process configuration. Thermal tests generally consist of measuring
            a sample’s response to either: (1) gradually increasing the
            temperature and recording the temperature at which the substance
            gives off heat (autoignition temperature), or (2) holding the
            substance at a given temperature for an extended period of time to
            evaluate stability at a given temperature (thermal stability). Both
            auto-ignition and thermal stability tests are used for in-process
            risk evaluation.
          </p>
          <p>
            Further details can be found in the SMS Sponsored Test Methods
            Matrix™ including videos, test setup pictures, test purpose, key
            test parameters and indicators:&nbsp;<a
              href="http://www.etusersgroup.org/ip-thermal-test/"
              target="_blank"
              >In-Process (IP) Series 1 – Thermal Test</a
            >
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="In-Process Test Series 2" />
        <Paragraph
          ><p>
            The In-Process Series 2 tests are completed when the material is not
            assumed to have explosive&nbsp;properties or in other words if it is
            not known if the substance is an explosive. The test series
            is&nbsp;equivalent to the UN Manual of Tests and Criteria Series 1,
            with the exception that instead of testing the substance in the
            condition offered for transport, the in-process condition (or a
            worst-case scenario) is tested.
          </p>
          <p>
            The tests determine if the substance has explosive properties such
            as propagating a shock or sustaining&nbsp;vigorous burning under
            pressure. The tests and the test details are not repeated here. They
            are found&nbsp;in Test Series 1 in the “Recommendations on the
            Transport of Dangerous Goods: Manual of Tests and&nbsp;Criteria,”
            Sixth Revised Edition, United Nations, New York and Geneva, 2015.
          </p>
          <p>
            If the substance fails any of the UN Manual Series 1 tests (shows
            explosive characteristics), it should be classified as an explosive
            (IP 1.1 or 1.3) for the in-process operation. If the material is
            shown not to have these explosive characteristics then the material
            may be classified as something other than an explosive.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="In-Process Test Series 3" />
        <Paragraph
          ><p>
            Test Series 3 is a compilation of tests to determine whether or not
            the material should be considered a high explosive (i.e.
            IP&nbsp;1.1) or whether the material may be considered in a lower
            explosive category (e.g. IP&nbsp;1.3). The test series contains four
            different tests: the thermal stability test, the small-scale burning
            test, the #8 cap test, and the card gap test.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Small-Scale Burning Test
          </h2>
          <p>
            This test is used to determine if unconfined samples, once ignited
            continue burning or transit to an explosion or detonation. A bed of
            sawdust, containing small samples of test material, is ignited and
            monitored. The test is a “go” (positive) if explosion or detonation
            occurs.
          </p>
          <p>
            Further details can be found in the SMS sponsored Test Methods
            Matrix™ including videos, test setup pictures, test purpose, key
            test parameters and indicators:&nbsp;<a
              href="http://www.etusersgroup.org/ip-small-scale-burning-test/"
              target="_blank"
              >In-Process (IP) Series 3 – Small-Scale Burning Test</a
            >
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Explosive Cap Testing (No. 8 Cap)
          </h2>
          <p>
            The No. 8 cap test is used to determine susceptibility of explosives
            to detonation from the energy delivered by a standard detonator.
            Sample detonation is determined by examining the witness plate. The
            criterion for detonation (positive result) is that the witness plate
            is torn or penetrated.
          </p>
          <p>
            Further details can be found in the SMS sponsored Test Methods
            Matrix™ including videos, test setup pictures, test purpose, key
            test parameters and indicators:&nbsp;<a
              href="http://www.etusersgroup.org/ip-cap-sensitivity-test/"
              target="_blank"
              >In-Process (IP) Series 3 – Cap Sensitivity Test</a
            >
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Shock Sensitivity Test
          </h2>
          <p>
            This test is similar to the UN gap test except that a shorter tube
            of material is used and a varied attenuator is placed between the
            test material and the pentolite booster. The attenuators provide a
            reduced shock source. The criterion for a “go” is that a clean hole
            is punctured through the witness plate. Typically, 12 trials are
            required to determine the 50% “go/ no-go” level. The test determines
            if a material will propagate an attenuated detonation.
          </p>
          <p>
            Further details can be found in the SMS sponsored Test Methods
            Matrix™ including videos, test setup pictures, test purpose, key
            test parameters and indicators:&nbsp;<a
              href="http://www.etusersgroup.org/nol-card-gap/"
              target="_blank"
              >In-Process (IP) Series 3 – NOL Card Gap Test</a
            >
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="In-Process Test Series 4" />
        <Paragraph
          ><p>
            Test Series 4 is used to determine additional material
            characteristics which are used to define in-process parameters, in
            addition to those examined by the DOT and BATF. If the process is
            designed within these parameters, the maximum credible event from a
            Class 1.3 material or process is a mass fire hazard. The series also
            includes tests to determine venting parameters for confined
            operations. If, however, the process parameters exceed the critical
            height or critical diameter, then explosion of the material due to
            self confinement (from a flame initiation), or propagation of a
            detonation is possible.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Process Simulation
          </h2>
          <p>
            Where the process is known, appropriate process simulations may be
            used. However, a change in the process may necessitate re-testing of
            some or all of the explosive substances used in the process.
            Appropriate process simulations may consist of modifications to the
            standard tests below, to represent worst-case confinement,
            configuration, and/or loading in the process.
          </p>
          <p>
            Further details can be found in the SMS sponsored Test Methods
            Matrix™ including videos, test setup pictures, test purpose, key
            test parameters and indicators:&nbsp;<a
              href="http://www.etusersgroup.org/process-simulation-tests/"
              target="_blank"
              >In-Process (IP) Series 4 – Process Simulation Tests</a
            >
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Critical Diameter Test
          </h2>
          <p>
            The critical diameter of an explosive is the largest diameter at
            which steady-state detonation cannot be maintained. The test uses
            varying diameter cylinders and a witness plate. A Comp C-4 or
            similar booster is used to initiate the sample. The test results are
            considered positive if the witness plate indicates detonation.
            Normally, the test shall be completed after three “no-go” reactions
            are obtained at a diameter one increment below a diameter that
            previously yielded a positive result.
          </p>
          <p>
            Further details can be found in the SMS sponsored Test Methods
            Matrix™ including videos, test setup pictures, test purpose, key
            test parameters and indicators:&nbsp;<a
              href="http://www.etusersgroup.org/critical-diameter-test/"
              target="_blank"
              >In-Process(IP) Series 4 – Critical Diameter Test</a
            >
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Critical Height (Mass) Test
          </h2>
          <p>
            The critical height (mass) test is used to determine the critical
            height at which a flame initiation transits to an explosive reaction
            (explosion or detonation). In this test, a flame initiator (bag
            igniter) is placed at the bottom of a pipe assembly filled with the
            test material. Pipes of varying lengths and diameters are used to
            contain the test material. The test is performed by selecting a
            diameter, and progressively changing the height of the pipe until
            the material transitions from burning to explosion or detonation.
            The diameter is then changed and the progressive height variation
            testing is repeated. Normally, a curve can be fitted using the data,
            to predict the critical height for other diameters as well. A “go”
            reaction, for explosion, is one in which the pipe is damaged. The
            test is concluded at each diameter by running a minimum of three
            successive trials that produce a “no-go” result at a height below a
            level that produces a positive reaction (explosion). This level is
            referred to as the critical height at that diameter.
          </p>
          <p>
            Further details can be found in the SMS sponsored Test Methods
            Matrix™ including videos, test setup pictures, test purpose, key
            test parameters and indicators:&nbsp;<a
              href="http://www.etusersgroup.org/critical-height-test/"
              target="_blank"
              >In-Process (IP) Series 4 – Critical Height Test</a
            >
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Internal Ignition (10-Gram Bag)
          </h2>
          <p>
            This test is similar to the internal ignition test described earlier
            except that a 10-gram bag igniter is used instead of the 20-gram
            bag. As stated previously, either the pipe or at least one of the
            end caps must be fragmented into at least two distinct pieces for a
            positive result. Three trials are performed unless a transition from
            deflagration to explosion occurs earlier. The test determines if a
            material will explode or detonate when ignited under confinement.
          </p>
          <p>
            Further details can be found in the SMS sponsored Test Methods
            Matrix™ including videos, test setup pictures, test purpose, key
            test parameters and indicators:&nbsp;<a
              href="http://www.etusersgroup.org/IP-internal-ignition-test/"
              target="_blank"
              >In-Process (IP) Series 4 – Internal Ignition Test</a
            >
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Koenen Test
          </h2>
          <p>
            Th Koenen test is used to determine the sensitiveness of a material
            to the effect of intense heat under vented confinement. In this
            test, the material is placed in a steel container with an orifice
            plate. The test apparatus is then placed in a protective steel box,
            and heated at a specified rate. A series of trials is conducted
            using different sizes of orifices. A “go” reaction is determined by
            examining the container. Conducting three successive “no-go”
            reactions with an orifice plate size above that which produced a
            positive result concludes the test. This orifice is called the
            limiting diameter. The limiting diameter may be used to evaluate the
            degree of venting required to avoid an explosion in the process.
          </p>
          <p>
            Further details can be found in the SMS sponsored Test Methods
            Matrix™ including videos, test setup pictures, test purpose, key
            test parameters and indicators:&nbsp;<a
              href="http://www.etusersgroup.org/ip-koenen-test/"
              target="_blank"
              >In-Process (IP) Series 4 – Koenen Test</a
            >
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="In-Process Test Series 5" />
        <Paragraph
          ><p>
            Series 5 tests are used to determine the hazard presented by
            in-process articles (IP 1.1, 1.2, 1.3, or 1.4). Tests are completed
            under conditions and worst-case in-process configurations, as
            identified through a risk assessment or process hazards analysis,
            with a safety factor. The tests represent energy stimuli common to
            in-process articles in an in-process environment: exposure to heat
            (curing, conditioning), free-fall impact (falls from material
            handling equipment, handling error by personnel),
            ignition/initiation of a donor in-process article in a simulated or
            worst-case process configuration, and exposure to intense heating or
            flame (facility fire).
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Thermal Characterization Tests
          </h2>
          <p>
            These tests are used to determine an in-process article’s thermal
            characteristics when subjected to worst-case normal/abnormal process
            conditions. The test conditions (test temperature(s), test
            duration(s), test quantity, sample configuration(s), pass/fail
            criteria, etc.) should reflect worst case in-process conditions for
            defined failure scenarios identified in the process risk assessment
            with appropriate safety factors applied to test temperature(s) and
            duration(s).<br />
            Thermal sensitivity from Series 1 testing can also be used to
            evaluate the in-process risk of the<br />
            substance(s) in the article. The process risk assessment may also
            identify other thermal tests, such as the slow cook-off test for
            articles, that may applicable for the specific process
            configuration.
          </p>
          <p>
            Further details can be found in the SMS sponsored Test Methods
            Matrix™ including videos, test setup pictures, test purpose, key
            test parameters and indicators:
            <a
              href="http://www.etusersgroup.org/thermal-characterization-tests/"
              target="_blank"
              >In-Process (IP) Series 5 – Thermal Characterization Tests</a
            >
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Drop Test
          </h2>
          <p>
            This test is used to determine whether an in-process article can
            withstand a free-fall impact without producing any significant fire
            or explosion hazards. The UN MTC and US DoD 12-meter Drop test
            for<br />
            articles, with a drop height of 12 meters, is the prescribed test
            for determining whether an article is too sensitive for transport.
            However, the drop height and test parameters for transport are
            likely not representative of the free-fall impact stimuli available
            in a specific process. Therefore, the test conditions (test
            quantity, configuration, temperature conditioning, drop height,
            impact surface, number of drops, pass/fail criteria, etc.) are
            defined by the process risk assessment with appropriate safety
            factors. For example, a 1.2 meter (4 foot) to 1.8 meter (6 foot)
            drop height may be applicable for simulating handling error by
            personnel at ground level. Higher drop heights may be appropriate if
            the in-process articles are conveyed to higher/lower building levels
            with a drop potential from material handling equipment (conveyors,
            forklifts, elevators, etc.). Drop heights should be determined by
            the risk assessment.
          </p>
          <p>
            Further details can be found in the SMS sponsored Test Methods
            Matrix™ including videos, test setup pictures, test purpose, key
            test parameters and indicators:<a
              href="http://www.etusersgroup.org/ip-drop-test/"
              target="_blank"
              >In-Process (IP) Series 5 – Drop Test</a
            >
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Worst-Case Propagation Tests
          </h2>
          <p>
            This test is used to determine whether a reaction from an unfinished
            article, which was accidentally fired or initiated, would propagate
            to other articles or parts of the process. This test is conducted by
            placing articles in a worst-case configuration (e.g. side-by-side,
            end-to-end, and/or in a pile). This test is similar to the stack
            test except that the articles are tested without packaging, as they
            are or may be found in the process, including during process upset.
            The unfinished or finished articles are placed on top of a steel
            witness plate. Sand filled inert containers or sandbags may be
            positioned on the sides and top of the test articles for added
            confinement. Wire or clamps may be used to hold the articles in
            place. An article near the center of the unpackaged articles is
            caused to function (donor). Evidence of propagation or any other
            observable reaction is recorded. Normally at least two trials are
            conducted.
          </p>
          <p>
            Further details can be found in the SMS sponsored Test Methods
            Matrix™ including videos, test setup pictures, test purpose, key
            test parameters and indicators:
            <a
              href="http://www.etusersgroup.org/propagation-tests/"
              target="_blank"
              >In-Process (IP) Series 6 – Propagation Tests</a
            >
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            External Fire Test
          </h2>
          <p>
            A stack of unfinished or finished articles as they appear in the
            manufacturing line is placed on a non-combustible surface (steel
            grate) above a lattice of dried wood soaked with diesel fuel or
            equivalent source. A wire basket or clamps may be used to hold the
            articles in place. Sufficient fuel is used to provide a 30-minute
            fire. The fire is ignited and the material is observed for: a)
            Evidence of detonation, deflagration or explosion of the total
            contents; b) Potentially hazardous fragmentation; and c) Thermal
            effects (i.e. size of the fireball, etc.).
          </p>
          <p>
            Further details can be found in the SMS sponsored Test Methods
            Matrix™ including videos, test setup pictures, test purpose, key
            test parameters and indicators:&nbsp;<a
              href="http://www.etusersgroup.org/ip-external-fire-test/"
              target="_blank"
              >In-Process (IP) Series 6 – External Fire Test</a
            >
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Protective-Enclosure Testing" />
        <Paragraph
          ><p>
            The use of technology to mitigate hazards is supported in civilian
            and military practice. Devices can be designed to fully contain or
            safely direct the deflagration and/or detonation effects of limited
            quantities of explosive material. Quantities of explosive may
            thereby be afforded the same level of isolation and safety as
            provided by the traditional quantity-distance (Q-D) separations.
          </p>
          <p>
            A blast chimney is an example of these technologies. A blast chimney
            or other technology must be made to withstand the maximum possible
            event plus a 25 percent overcharge from the type and configuration
            of explosive contained within, if used in lieu of Q-D separations.
          </p>
          <p>
            Shielding devices are evaluated for the following (MIL-STD-398):
          </p>
          <ul>
            <li>
              <span style="text-decoration: underline;">Blast Attenuation</span>
              – 1) to provide protection from accidental detonation, shall be
              designed to prevent exposure of operating personnel to peak
              positive incident pressures above 2.3 psi (16 kN/m2) or peak
              positive normal reflected pressure above 5.0 psi (34 kN/m2), and
              2) to provide protection from intentional detonation of
              ammunition, shall be designed to prevent exposure of operating
              personnel to impulse noise levels exceeding 140 decibles.
            </li>
            <li>
              <span style="text-decoration: underline;"
                >Fragmentation Confinement</span
              >
              – 1) to contain all fragmentation or direct fragmentation away
              from area requiring protection, 2) to prevent generation of
              secondary fragmentation within areas requiring protection, and 3)
              to prevent movement, overturning, or structural deflections that
              could result in personnel injury.
            </li>
            <li>
              <span style="text-decoration: underline;"
                >Thermal Effects Attenuation</span
              >
              – 1) to limit exposure of personnel to a critical heat flux value
              based on the total time of exposure (designated by the equation µ
              [cal/(cm2)sec] = 0.62t^(-0.7423), where t is time in seconds that
              a person is exposed to the radiant heat), 2) all operating
              personnel shall be located at a distance from the shield that
              assures their exposure is less than the heat flux determined from
              specified equations, and 3) the upper torso of an operator’s body
              shall not be subjected to any visible fire or flame; flame
              impingement upon the lower portion of the body may be permitted
              provided that the heat flux specified above is not exceeded.
            </li>
          </ul>
          <p>
            Any overpressure discharged must be done so in a manner that safely
            directs or dissipates the effects thereof. A blast chimney or other
            technology must be made to withstand the maximum credible event from
            the type and configuration of explosive contained within, if used as
            workstation protection in lieu of separated or unattended
            operations.
          </p>
          <p>
            Further details can be found in the SMS sponsored Test Methods
            Matrix™ including videos, test setup pictures, test purpose, key
            test parameters and indicators:
            <a
              href="http://www.etusersgroup.org/shield-testing/"
              target="_blank"
              >In-Process Testing – Workstation Protection Testing</a
            >
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "In Process Tests",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "Equating the transportation classification to an un-packaged or un-finished article or substance can expose manufacturers and users to un-foreseen risks. In-process testing augments risk assessments of processing and handling of PEP substances and articles."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.inprocess-tests {
  a {
    color: #aa222c;
  }
}
</style>
